import React from "react"
import { Div, Text, Image, Input, Anchor, Button } from "atomize"

import LoginForm from "./LoginForm"
import SideDrawer from "../atoms/SideDrawer"

const LoginModal = ({
  isOpen,
  onClose,
  onClickForgotPasswordBtn,
  onClickSignUpBtn,
}) => {
  return (
    <SideDrawer isOpen={isOpen} onClose={onClose}>
      <Div
        d="flex"
        justify="space-between"
        align="center"
        m={{ b: "2.5rem" }}
        p={{ b: "2.5rem" }}
        border={{ b: "1px solid" }}
        borderColor="yellowPink"
      >
        <Text
          textTransform="uppercase"
          textWeight="bold"
          fontFamily="secondary"
          textSize="display1"
        >
          log in
        </Text>
        <Div
          style={{ lineHeight: "0" }}
          bg="lightPink"
          shadow="2"
          hoverShadow="3"
          cursor="pointer"
          rounded="sm"
          onClick={onClose}
        >
          <Image src="/close.svg" h="1rem" w="1rem" p="10px" />
        </Div>
      </Div>

      <LoginForm />
      <Anchor
        textDecor="underline"
        textColor="blue"
        textSize="paragraph"
        hoverTextColor="navy"
        transition
        m={{ t: "1rem" }}
        onClick={onClickForgotPasswordBtn}
      >
        Forgot your password?
      </Anchor>
      <Button
        shadow="2"
        m={{ t: "2.5rem" }}
        hoverShadow="1"
        bg="pink"
        textTransform="uppercase"
        rounded="circle"
        textColor="blue"
        textWeight="bold"
        fontFamily="secondary"
        textSize="header1"
        h="2.5rem"
        overflow="hidden"
        onClick={onClickSignUpBtn}
      >
        Sign up
      </Button>
    </SideDrawer>
  )
}

export default LoginModal
