import {get} from "lodash"
import React, {useContext, useEffect, useState} from "react"
import {Div, Icon, Image, Text} from "atomize"
import {useLazyQuery} from "@apollo/client"
import Slider from "rc-slider"
import {navigate} from "gatsby"
import BlueButton from "../common/molecules/BlueButton"

import {GET_PRODUCTS_BY_IDS} from "../../graphql/queries"
import {removeItemsFromRechargeCheckout, updateItemsInRechargeCheckout,} from "../../services/cartService"
import {AppContext} from "../../context";
import {isBefore} from "date-fns";

const RechargeCartModal = ({
                             cart,
                             onClose,
                             toggleCart,
                             email,
                             rechargeCart,
                             checkoutCreate,
                             replaceCheckoutLineItems,
                             updateRechargeCheckout,
                             removeCheckoutLineItems,
                             setLoading,
                           }) => {
  const [error, setError] = useState(null)
  const [state, dispatch] = useContext(AppContext);

  const toggleRechargeCart = toggleCart
  const [getProductsByIDS, {loading: gettingProducts}] = useLazyQuery(
    GET_PRODUCTS_BY_IDS,
    {
      fetchPolicy: "network-only",
      onCompleted: result => {
        const variants = get(result, "nodes")

        const itemOutOfStock = variants.find(
          variant => variant.quantityAvailable < 1
        )

        if (itemOutOfStock) {
          setError(
            `${itemOutOfStock.product.title} - ${itemOutOfStock.title} is out of stock. Please remove the item to proceed to checkout.`
          )
        } else {
          const url = `https://checkout.rechargeapps.com/r/checkout/${rechargeCart.token}?myshopify_domain=not-pot.myshopify.com`

          window.location.href = url
        }
      },
    }
  )

  /**
   * on click - button
   *
   * @param {*} item
   */
  const onDecreaseQuantity = item => {
    setError(null)
    // if (replaceCheckoutLineItemLoading) return false

    if (item.quantity === 1) {
      removeItemsFromRechargeCheckout({
        updateRechargeCheckout,
        rechargeCart: rechargeCart,
        cart: cart,
        cartItem: item,
        checkoutCreate,
        replaceCheckoutLineItems,
        email,
        removeCheckoutLineItems,
        setLoading,
      })
    } else {
      updateItemsInRechargeCheckout({
        rechargeCheckout: rechargeCart,
        quantity: -1,
        item: item,
        updateRechargeCheckout,
        toggleRechargeCart,
      })
    }
  }

  /**
   * on click + button
   *
   * @param {*} item
   */
  const onIncreaseQuantity = item => {
    setError(null)

    updateItemsInRechargeCheckout({
      rechargeCheckout: rechargeCart,
      quantity: 1,
      item,
      updateRechargeCheckout,
      toggleRechargeCart,
    })
  }

  /**
   * on remove item
   * @param {*} item
   */
  const onRemoveItem = item => {
    setError(null)

    removeItemsFromRechargeCheckout({
      updateRechargeCheckout,
      rechargeCart: rechargeCart,
      cart: cart,
      cartItem: item,
      checkoutCreate,
      replaceCheckoutLineItems,
      email,
      removeCheckoutLineItems,
      setLoading,
    })
  }

  const getSubTotal = () => {
    const items = get(rechargeCart, "line_items", [])
    const subTotal = items
      .reduce(
        (acc, item) => parseFloat(item.price) * parseFloat(item.quantity) + acc,
        0
      )
      .toFixed(2)
    return subTotal
  }

  const getPercent = () => {
    const subTotal = getSubTotal()
    const percentage = (subTotal / 30) * 100
    return parseFloat(percentage)
  }

  const handleRedirectToPDP = node => {
    const isProduct = node.variant_title === "Default Title"
    const slug = node.title.toLowerCase().replace(/\s/g, "-")
    isProduct && navigate(`/products/${slug}`)
    // : navigate(`/products/${slug}/${node.variant_title.toLowerCase()}`)

    toggleRechargeCart({
      variables: {cartDrawerVisible: false},
    })
  }
  useEffect(() => {
    if (state) {
      const {tracking} = state;
      if (rechargeCart && rechargeCart.note_attributes) {
        if (rechargeCart.note_attributes.referCode !== tracking.referCode) {
          if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode) {
            const item = get(rechargeCart, "line_items", [])[0];
            updateItemsInRechargeCheckout({
              rechargeCheckout: rechargeCart,
              quantity: 0,
              item,
              updateRechargeCheckout
            })
          }


        }

      }
    }

  }, [rechargeCart, state])
  return (
    <>
      <Div h="100%" d="flex" flexDir="column">
        <Div
          d="flex"
          justify="space-between"
          align="center"
          m={{b: "2.25rem"}}
          p={{r: "1rem"}}
        >
          <Text
            textTransform="uppercase"
            textWeight="bold"
            fontFamily="secondary"
            textSize="display1"
          >
            cart
          </Text>
          <Div
            style={{lineHeight: "0"}}
            bg="lightPink"
            shadow="2"
            hoverShadow="3"
            transition
            cursor="pointer"
            rounded="sm"
            onClick={() => onClose(false)}
          >
            <Image src="/close.svg" h="1rem" w="1rem" p="10px"/>
          </Div>
        </Div>

        <Div
          flexGrow="1"
          overflow="auto"
          flexWrap="nowrap"
          p={{r: "1.5rem"}}
          m={{b: {xs: "9.3rem", sm: "0rem"}, r: "-1.5rem"}}
          style={{overscrollBehavior: "contain"}}
        >
          {get(rechargeCart, "line_items", []).length > 0 && (
            <Div
              d="flex"
              flexDir="column"
              m={{r: "1rem"}}
              border={{t: "1px solid"}}
              borderColor="yellowPink"
              textWeight="500"
              p={{y: "2rem"}}
            >
              <Div p={{y: "0.3rem"}} d="flex" flexDir="column">
                <Text textSize="body" m={{b: ".8rem"}}>
                  {100 >= 30 ? "Congrats! You get free standard shipping."
                    : `You're $${(30 - getSubTotal()).toFixed(
                      2
                    )} away from free shipping.`}
                </Text>
                <div style={{position: "relative"}}>
                  <Slider
                    disabled={true}
                    // value={getPercent()}
                    value={100}
                    railStyle={{
                      backgroundColor: "#F8D4E4",
                      height: "4px",
                      borderRadius: "100px",
                    }}
                    trackStyle={{
                      backgroundColor: "#283377",
                      height: "4px",
                      borderRadius: "100px",
                      position: "absolute",
                      top: "0",
                    }}
                    handleStyle={{
                      display: 100 >= 30 ? "none" : "block", //getSubTotal()
                      position: "absolute",
                      backgroundColor: "#F8D4E4",
                      height: "16px",
                      width: "16px",
                      borderRadius: "100px",
                      top: "-6px",
                      transform: "translate(-50%)",
                      boxShadow:
                        " -8px -8px 12px #FFFFFF, 6px 6px 12px rgba(255, 211, 95, 0.3)",
                    }}
                  />
                </div>
              </Div>
            </Div>
          )}

          {get(rechargeCart, "line_items", []).length === 0 && (
            <Div flexGrow="1 1 0%" m={{t: "2rem"}}>
              <Text>Bag is Empty</Text>
            </Div>
          )}
          {get(rechargeCart, "line_items", []).map((item, index) => {
            return (
              <Div key={`cart-list-item-${index}`}>
                {/* {[1, 2, 3].map(id => {
            return ( */}
                <Div
                  p={{y: "2.5rem"}}
                  m={{r: "1rem"}}
                  border={{t: "1px solid"}}
                  borderColor="yellowPink"
                  d="flex"
                  //key={id}
                >
                  <Div
                    onClick={() => handleRedirectToPDP(item)}
                    cursor="pointer"
                    bgImg={get(item, "image")}
                    bgPos="center"
                    bgSize="cover"
                    rounded="10px"
                    m={{r: "1.25rem"}}
                    h="4rem"
                    w="4rem"
                  />
                  <Div flexGrow="1">
                    <Div d="flex" justify="space-between" align="center">
                      <Text
                        onClick={() => handleRedirectToPDP(item)}
                        cursor="pointer"
                        textWeight="bold"
                      >
                        {item.title}
                      </Text>

                      <Div
                        style={{lineHeight: "0"}}
                        bg="lightPink"
                        shadow="2"
                        cursor="pointer"
                        rounded="circle"
                        m={{l: "20px"}}
                        onClick={() => {
                          onRemoveItem(item)
                        }}
                      >
                        <Image src="/close.svg" h="10px" w="10px" p="5px"/>
                      </Div>
                    </Div>
                    <Div>
                      {item.variant_title !== "Default Title" && (
                        <Text m={{t: "10px"}} textSize="paragraph1">
                          {item.variant_title}
                        </Text>
                      )}
                      <Text m={{t: "5px"}} textSize="paragraph1">
                        ${(item.price * item.quantity).toFixed(2)}
                      </Text>
                      {item.charge_interval_frequency && (
                        <Text
                          textSize="paragraph1"
                          m={{t: "5px"}}
                          textColor="gray900"
                        >
                          {`deliver every ${item.charge_interval_frequency} Days`}
                        </Text>
                      )}
                      {/* //Counter for products */}
                      <Div
                        d="flex"
                        rounded="lg"
                        m={{t: "10px"}}
                        shadow="2"
                        maxW="4rem"
                        justify="space-between"
                      >
                        <Text
                          textWeight="bold"
                          p="2px 10px"
                          cursor="pointer"
                          onClick={() => {
                            onDecreaseQuantity(item)
                          }}
                          // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                        >
                          -
                        </Text>
                        <Text textWeight="bold" p="2px 0">
                          {/* {replaceCheckoutLineItemLoading ? (
                            <Image src="/loader.svg" />
                          ) : ( */}
                          {item.quantity}
                          {/* )} */}
                        </Text>
                        <Text
                          textWeight="bold"
                          p="2px 10px"
                          cursor="pointer"
                          onClick={() => {
                            onIncreaseQuantity(item)
                          }}
                          // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                        >
                          +
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
            )
          })}

          {get(rechargeCart, "line_items", []).length > 0 && (
            <Div
              d="flex"
              flexDir="column"
              m={{r: "1rem"}}
              border={{t: "1px solid"}}
              borderColor="yellowPink"
              textWeight="500"
              p={{y: "2rem"}}
            >
              <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                <Text textSize="body">Subtotal</Text>
                <Text textSize="body" textWeight="700">
                  ${getSubTotal()}
                </Text>
              </Div>
              <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                <Text textSize="body">Tax</Text>
                <Text textSize="body" style={{color: "#6C97C4"}}>
                  Calculated in checkout
                </Text>
              </Div>
              <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                <Text textSize="body">Shipping</Text>
                <Text textSize="body" style={{color: "#6C97C4"}}>
                  Calculated in checkout
                </Text>
              </Div>
              {rechargeCart?.applied_discount?.value ?
                <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                  <Text textSize="body">Discount</Text>
                  <Text textSize="body">
                    (${rechargeCart.applied_discount.value})
                  </Text>
                </Div> : ''}
            </Div>
          )}
        </Div>

        {error && (
          <Div>
            <Text textColor="danger700" m={{b: "1rem"}} transition>
              {error}
            </Text>
          </Div>
        )}

        {get(rechargeCart, "line_items", []).length > 0 && (
          <Div
            m={{r: {sm: "1rem"}}}
            pos={{xs: "fixed", sm: "static"}}
            left="1rem"
            right="1rem"
            bottom="0rem"
            w={{sm: "100%"}}
          >
            <Div
              className="safe-margin"
              style={{
                marginRight: "-1.5rem",
                display: "flex",
                flexDirection: "column",
              }}
              m={{l: {sm: "-36px", xs: "-26px"}}}
              p={{b: "20px", x: {sm: "25px", xs: "50px"}}}
            >
              <Div
                d="flex"
                justify="space-between"
                border={{t: "1px solid"}}
                borderColor="yellowPink"
                p={{y: "20px"}}
                style={{alignSelf: "center"}}
                w={{lg: "295px", xs: "100%"}}
              >
                <Text textSize="body">Estimated Total</Text>
                <Text textSize="body" textWeight="700">
                  ${rechargeCart?.applied_discount?.value ?
                  rechargeCart.total_price
                  : getSubTotal()}
                </Text>
              </Div>
              <BlueButton
                style={{alignSelf: "center"}}
                w={{lg: "295px", xs: "100%"}}
                text="Checkout"
                rounded="lg"
                m={{b: "10px"}}
                onClick={() => {
                  const url = `https://checkout.rechargeapps.com/r/checkout/${rechargeCart.token}?myshopify_domain=not-pot.myshopify.com`
                  getProductsByIDS({
                    variables: {
                      ids: get(rechargeCart, "line_items", []).map(item =>
                        btoa(`gid://shopify/ProductVariant/${item.variant_id}`)
                      ),
                    },
                  })
                  // window.location.href = url
                }}
                disabled={gettingProducts}
                prefix={
                  <Icon
                    name="Loading"
                    pos="absolute"
                    top="50%"
                    left="8.5%"
                    transform="translateY(-50%)"
                    size="25px"
                    color="white"
                    style={{zIndex: 1, opacity: gettingProducts ? 1 : 0}}
                    m={{r: "0.5rem"}}
                  />
                }
              />
            </Div>
          </Div>
        )}
      </Div>
    </>
  )
}

export default RechargeCartModal
