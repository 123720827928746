import {get, reject} from "lodash"
import ls from "local-storage"
import {isBefore} from "date-fns";

/**
 * adds item to existing checkout or create a new checkout
 *
 * @param {*} param0
 */
export const addItemToCheckout = ({
                                    checkout,
                                    email,
                                    checkoutCreate,
                                    checkoutLineItemsAdd,
                                    variant,
                                    quantity = 1,
                                  }) => {
  const items = [
    {
      variantId: window.btoa(`gid://shopify/ProductVariant/${variant._id}`),
      quantity: quantity,
    },
  ]

  // if there is no existing checkout || or current checkout is completed
  if (!checkout || checkout?.orderStatusUrl) {
    checkoutCreate({
      variables: {
        input: {
          lineItems: items,
          email,
        },
      },
    })

    return
  }

  // if there is a existing checkout, then add item to that checkout
  checkoutLineItemsAdd({
    variables: {
      items: items,
      checkoutId: checkout.id,
    },
  })
}

/**
 * adds items to existing checkout or create a new checkout
 *
 * @param {*} param0
 */
export const createCheckoutWithItems = ({email, checkoutCreate, items}) => {
  checkoutCreate({
    variables: {
      input: {
        lineItems: items,
        email
      },
    },
  })
}

/**
 * add item to recharge checkout
 *
 * @param {*} param0
 */
export const addItemToRechargeCheckout = props => {
  const {checkout, rechargeCheckout, updateRechargeCheckout, email} = props
  let lineItems = [
    {
      variant_id: props.variant._id,
      quantity: props.quantity,
      charge_interval_frequency: props.frequency,
      order_interval_unit: props.frequency ? "day" : undefined,
      order_interval_frequency: props.frequency,
    },
  ];

  // if there is no recharge checkout or current checkout is completed create a new
  if (!rechargeCheckout || rechargeCheckout?.completed_at) {
    // if there is normal checkout and it's not completed, then create new recharge
    if (checkout && !checkout?.orderStatusUrl) {
      lineItems = lineItems.concat(copyRegularItems(checkout))
    }

    createRechargeCheckout({lineItems, ...props})

    return true
  }

  replaceItemsFromRechargeCheckout({...props, updateRechargeCheckout})
}

/**
 * remove item from checkout
 *
 * @param {*} param0
 */
export const removeItemsFromCheckout = ({
                                          removeCheckoutLineItems,
                                          checkout,
                                          cartItem,
                                        }) => {
  const itemsToRemove = [cartItem.id]

  removeCheckoutLineItems({
    variables: {lineItemIds: itemsToRemove, checkoutId: checkout.id},
  })
}

/**
 * remove item from recharge checkout
 *
 * @param {*} param0
 */
export const removeItemsFromRechargeCheckout = ({
                                                  cart,
                                                  rechargeCart,
                                                  cartItem,
                                                  email,
                                                  checkoutCreate,
                                                  replaceCheckoutLineItems,
                                                  updateRechargeCheckout,
                                                  removeCheckoutLineItems,
                                                  setLoading,
                                                }) => {
  const items = get(rechargeCart, "line_items", [])

  const itemsAfterRemoving = reject(items, item => {
    return (
      item.variant_id == cartItem.variant_id &&
      item.charge_interval_frequency == cartItem.charge_interval_frequency
    )
  })
  const hasOnlyRegularItems = checkIfCartHasOnlyRegularItems(itemsAfterRemoving)
  if (itemsAfterRemoving.length <= 0) {
    ls.remove("lastRechargeCheckoutID")
  }

  if (hasOnlyRegularItems && itemsAfterRemoving.length > 0) {
    if (itemsAfterRemoving.length > 0) {
      if (itemsAfterRemoving.length === 1) {
        if (itemsAfterRemoving[0].type === "ONETIME") {
          setLoading(true)
          const items = [
            {
              variantId: window.btoa(`gid://shopify/ProductVariant/${itemsAfterRemoving[0].variant_id}`),
              quantity: itemsAfterRemoving[0].quantity,
            },
          ];
          const email = undefined;
          checkoutCreate({
            variables: {
              input: {
                lineItems: items,
                email,
              },
            },
          })
        } else {
          replaceCheckoutLineItems({
            variables: {
              checkoutId: cart?.id,
              items: itemsAfterRemoving.map(item => {
                return {
                  variantId: window.btoa(
                    `gid://shopify/ProductVariant/${item?.variant_id}`
                  ),
                  quantity: item?.quantity,
                }
              }),
            },
          })
        }
      } else {
        replaceCheckoutLineItems({
          variables: {
            checkoutId: cart?.id,
            items: itemsAfterRemoving.map(item => {
              return {
                variantId: window.btoa(
                  `gid://shopify/ProductVariant/${item?.variant_id}`
                ),
                quantity: item?.quantity,
              }
            }),
          },
        })
      }
      updateRechargeCheckout({
        variables: {checkout: null},
      })
      ls.remove("lastRechargeCheckoutID")
    }
  } else {

    const checkoutUpdateOptions = {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        lineItems: itemsAfterRemoving,
        checkoutToken: rechargeCart.token,
      }),
      referrerPolicy: 'origin-when-cross-origin'
    }
    fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/update`, checkoutUpdateOptions)
      .then(response =>
        response.json()
      ).then(result => {
      const {checkout = {}} = result;
      updateRechargeCheckout({
        variables: {checkout: checkout},
      })
    })
    //
    if (!hasOnlyRegularItems) {
      ls.remove('lastCheckoutID')
    }
  }
}

/**
 * replaces items from checkout
 * using it for increasing or decreasing quanity
 *
 * @param {*} param0
 */
export const replaceItemsFromCheckout = ({
                                           replaceCheckoutLineItems,
                                           cartItem,
                                           checkout,
                                           quantity,
                                         }) => {
  const cartItems = get(checkout, "lineItems.edges", [])
  const items = []

  cartItems.forEach(item => {
    if (
      cartItem.variant &&
      item.node.variant &&
      cartItem.variant.id !== item.node.variant.id
    ) {
      items.push({
        variantId: window.btoa(item.node.variant.id),
        quantity: item.node.quantity,
      })
    }
  })

  if (cartItem.variant) {
    items.push({
      variantId: window.btoa(cartItem.variant.id),
      quantity: quantity,
    })
  }

  replaceCheckoutLineItems({
    variables: {items: items, checkoutId: checkout.id},
  })
}

/**
 * replaces items from checkout
 * using it for increasing or decreasing quanity
 *
 * @param {*} param0
 */
export const replaceItemsFromRechargeCheckout = props => {
  const {rechargeCheckout: checkout, variant, frequency} = props

  const cartItems = get(checkout, "line_items", [])
  const items = JSON.parse(JSON.stringify(cartItems))
  const alreadyExistingItem = items.find(
    item =>
      item.variant_id == variant._id &&
      item.charge_interval_frequency == frequency
  )

  switch (true) {
    case alreadyExistingItem &&
    alreadyExistingItem.charge_interval_frequency === frequency:
      const index = items.findIndex(
        item =>
          item.variant_id == variant._id &&
          item.charge_interval_frequency == frequency
      )
      items[index].quantity = items[index].quantity + props.quantity
      break

    default:
      items.push({
        variant_id: variant._id,
        quantity: props.quantity,
        charge_interval_frequency: frequency,
        order_interval_unit: frequency ? "day" : undefined,
        order_interval_frequency: frequency,
      })
  }

  updateRechargeCheckout({
    checkout,
    items,
    toggleRechargeCart: props.toggleRechargeCart,
    updateRechargeCheckout: props.updateRechargeCheckout,
    setUpdatingRechargeCheckout: props.setUpdatingRechargeCheckout,
  })
}

/**
 * replaces items from checkout
 * using it for increasing or decreasing quanity
 *
 * @param {*} param0
 */
export const updateItemsInRechargeCheckout = props => {
  const {rechargeCheckout: checkout, item: currentItem, frequency} = props

  const cartItems = get(checkout, "line_items", [])
  const items = JSON.parse(JSON.stringify(cartItems))

  const index = items.findIndex(
    item =>
      item.variant_id == currentItem.variant_id &&
      item.charge_interval_frequency == currentItem.charge_interval_frequency
  )

  items[index].quantity = items[index].quantity + props.quantity

  updateRechargeCheckout({
    checkout,
    items,
    toggleRechargeCart: props.toggleRechargeCart,
    updateRechargeCheckout: props.updateRechargeCheckout,
  })
}

/**
 * saves checkout id in localstorage
 *
 * @param {*} checkout
 */
export const saveCheckoutInLocalStorage = checkout => {
  ls("lastCheckoutID", checkout.id)
}

/**
 * saves checkout id in localstorage
 *
 * @param {*} checkout
 */
export const saveRechargeCheckoutInLocalStorage = checkout => {
  ls("lastRechargeCheckoutID", checkout.token)
}

/**
 * counts the total number of items in a cart
 *
 * @param {*} cart
 */
export const getTotalCartCount = (cart, rechargeCheckoutData, loading) => {
  if (loading) return 0

  if (get(rechargeCheckoutData, "rechargeCheckout.completed_at") === null) {
    return get(rechargeCheckoutData.rechargeCheckout, "line_items", []).reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.quantity
      },
      0
    )
  }

  return get(cart, "lineItems.edges", []).reduce(
    (accumulator, currentValue, currentIndex, array) => {
      return accumulator + currentValue.node.quantity
    },
    0
  )
}

export const syncGuestCartWithCustomerCart = ({
                                                guestCheckout,
                                                customer,
                                                checkoutLineItemsAdd,
                                                checkoutCreate,
                                              }) => {
  const userCheckout = get(customer, "lastIncompleteCheckout")
  const guestCartItems = get(guestCheckout, "lineItems.edges", [])
  const userCartItems = get(userCheckout, "lineItems.edges", [])
  const items = []

  if (guestCartItems.length > 0) {
    guestCartItems.forEach(guestCartItem => {
      if (
        userCartItems.find(
          userCartItem =>
            userCartItem.node.variant.id === guestCartItem.node.variant.id
        )
      ) {
        return false
      }

      items.push({
        variantId: guestCartItem.node.variant.id,
        quantity: guestCartItem.node.quantity,
      })
    })

    // if there is no existing checkout || or current checkout is completed
    if (!userCheckout || userCheckout?.orderStatusUrl) {
      checkoutCreate({
        variables: {
          input: {
            lineItems: items,
            email: customer.email,
          },
        },
      })

      return
    }

    // if there is a existing checkout, then add item to that checkout
    checkoutLineItemsAdd({
      variables: {
        items: items,
        checkoutId: userCheckout.id,
      },
    })
  }
}

/**
 * copies regular items
 */
export const copyRegularItems = checkout => {
  const lineItems = []

  if (get(checkout, "lineItems.edges", []).length > 0) {
    const regularCartItems = get(checkout, "lineItems.edges", [])

    regularCartItems.map(item => {
      lineItems.push({
        quantity: item.node.quantity,
        variant_id: atob(item.node.variant.id).split("/").slice(-1)[0],
      })
    })
  }

  return lineItems
}

/**
 * creates a new recharge checkout, save the token in localStorage
 * and update local cache to update view
 *
 * @param {*} param0
 */
export const createRechargeCheckout = ({
                                         lineItems,
                                         email,
                                         updateRechargeCheckout,
                                         toggleRechargeCart,
                                         setUpdatingRechargeCheckout,
                                       }) => {
  setUpdatingRechargeCheckout(true)
  const notpotTracker = ls('notpotTracker');
  let tracking = null;
  const trackingParams = {...notpotTracker};
  if (trackingParams && !trackingParams.expired && trackingParams.expiresAt && isBefore(new Date(), new Date(trackingParams.expiresAt)) && !trackingParams.email && trackingParams.customerId && trackingParams.referCode) {
    tracking = {...trackingParams}
  } else {
    tracking = null;
  }
  const checkoutOptions = {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      lineItems,
      email,
      tracking
    }),
    referrerPolicy: 'origin-when-cross-origin'
  }
  fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/create`, checkoutOptions)
    .then(response => response.json()).then(result => {
    const {checkout = {}} = result;
    saveRechargeCheckoutInLocalStorage(checkout)
    updateRechargeCheckout({
      variables: {
        checkout: checkout,

      },
    })
    setUpdatingRechargeCheckout(false)
    toggleRechargeCart({
      variables: {cartDrawerVisible: true},
    })
  })
}

/**
 * update recharge checkout and update cache
 *
 * @param {*} param0
 */
export const updateRechargeCheckout = ({
                                         checkout,
                                         items,
                                         updateRechargeCheckout,
                                         toggleRechargeCart,
                                         setUpdatingRechargeCheckout = () => {
                                         },
                                       }) => {

  setUpdatingRechargeCheckout(true)
  const notpotTracker = ls('notpotTracker');
  let tracking = null;
  const trackingParams = {...notpotTracker};
  if (trackingParams && !trackingParams.expired && trackingParams.expiresAt && isBefore(new Date(), new Date(trackingParams.expiresAt)) && !trackingParams.email && trackingParams.customerId && trackingParams.referCode) {
    tracking = {...trackingParams}
  } else {
    tracking = null;
  }
  const checkoutOptions = {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      lineItems: items,
      checkoutToken: checkout.token,
      tracking
    }),
    referrerPolicy: 'origin-when-cross-origin'
  }
  fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/update`, checkoutOptions)
    .then(response =>
      response.json()
    ).then((result) => {
    const {checkout = {}} = result
    updateRechargeCheckout({
      variables: {checkout},
    })
    setUpdatingRechargeCheckout(false)
    toggleRechargeCart({
      variables: {cartDrawerVisible: true},
    })
  }).catch(e => {
    console.error(e)
  })
}

/**
 * checks if cart has regular items only
 *
 * @param {*} items
 */
export const checkIfCartHasOnlyRegularItems = items => {
  return items.filter(item => item.charge_interval_frequency).length == 0
}

// export const allremoveCheckout = () => {
//  ls.remove("lastRechargeCheckoutID")
//  ls.remove("lastCheckoutID")
//  return true;
// }
