import React, {useContext, useEffect, useState} from "react"
import {get} from "lodash"
import {useMutation} from "@apollo/client"
import {useLocation} from "@reach/router"
import {Div, Icon, Image, Text} from "atomize"
import Slider from "rc-slider"
import {navigate} from "gatsby"

import SideDrawer from "../common/atoms/SideDrawer"
import BlueButton from "../common/molecules/BlueButton"
import RechargeCartModal from "./RechargeCartModal"

import {
  removeItemsFromCheckout,
  replaceItemsFromCheckout,
  saveCheckoutInLocalStorage,
  syncGuestCartWithCustomerCart,
} from "../../services/cartService"
import {
  ADD_ATTRIBUTES,
  APPLY_DISCOUNT,
  CHECKOUT_CREATE,
  CHECKOUT_LINE_ITEMS_ADD,
  CHECKOUT_LINE_ITEMS_REMOVE,
  CHECKOUT_LINE_ITEMS_REPLACE,
  REMOVE_DISCOUNT,
  UPDATE_RECHARGE_CHECKOUT
} from "../../graphql/queries"
import {trackCheckout} from "../../helpers/freshpaint"
import {AppContext} from "../../context";
import {isBefore} from "date-fns";

const CartModal = ({
                     guestCart,
                     rechargeCart,
                     customer,
                     isOpen,
                     onClose,
                     toggleCart,
                     variant,
                     productCount,
                   }) => {
  const location = useLocation()
  const [state, dispatch] = useContext(AppContext);

  const [checkingOutShopify, setCheckingOutShopify] = useState(false)

  const cart = get(customer, "lastIncompleteCheckout") || guestCart

  const [loading, setLoading] = useState(false)
  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      toggleCart({variables: {cartDrawerVisible: true}})
      //window.history.pushState("header", "Not Pot", "/#Cart")
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      setTimeout(function () {
        setLoading(false);

      }, 1000)
    }
  })

  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD)
  const [updateRechargeCheckoutInCache] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  const [
    removeCheckoutLineItems,
    {loading: removeCheckoutLineItemLoading, data: removeCheckoutLineData},
  ] = useMutation(CHECKOUT_LINE_ITEMS_REMOVE, {
    onCompleted: result => {
      setLoading(false);
      // ls("ShowCart", true)
      // window.location.reload()
      //refetchCheckout()
    },
  })

  const [
    replaceCheckoutLineItems,
    {loading: replaceCheckoutLineItemLoading, data: replaceCheckoutLineData},
  ] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE, {
    onCompleted: result => {
      setLoading(false);
      //  ls("ShowCart", true)
      // window.location.reload()
      // refetchCheckout()
    },
  })

  /**
   * on click - button
   *
   * @param {*} item
   */

  const [addAttributes] = useMutation(ADD_ATTRIBUTES)

  const [removeDiscount] = useMutation(REMOVE_DISCOUNT, {
    onError: (error => console.error(error))
  })
  const [applyDiscount] = useMutation(APPLY_DISCOUNT, {
    onError: (error => console.error(error))
  })
  const onDecreaseQuantity = item => {
    if (replaceCheckoutLineItemLoading) return false

    if (item.quantity === 1) {
      removeItemsFromCheckout({
        removeCheckoutLineItems,
        checkout: cart,
        cartItem: item,
      })
    } else {
      replaceItemsFromCheckout({
        replaceCheckoutLineItems,
        checkout: cart,
        quantity: item.quantity - 1,
        cartItem: item,
      })
    }
  }

  /**
   * on click + button
   *
   * @param {*} item
   */
  const onIncreaseQuantity = item => {
    if (replaceCheckoutLineItemLoading) return false

    replaceItemsFromCheckout({
      replaceCheckoutLineItems,
      checkout: cart,
      quantity: item.quantity + 1,
      cartItem: item,
    })
  }

  /**
   * on remove item
   * @param {*} item
   */
  const onRemoveItem = item => {
    removeItemsFromCheckout({
      removeCheckoutLineItems,
      checkout: cart,
      cartItem: item,
    })
  }
  /**
   * Remove Cart ID
   */
  const removels = () => {
    // ls("aftercheckoutCartID", ls("lastCheckoutID"))
    // ls("aftercheckoutRechargeCartID", ls("lastRechargeCheckoutID"))
    // ls.remove("lastCheckoutID")
    // ls.remove("lastRechargeCheckoutID")
  }
  /**
   * sync guest cart
   */
  useEffect(() => {
    if (
      get(guestCart, "id") &&
      get(customer, "lastIncompleteCheckout.id") &&
      get(guestCart, "id") !== get(customer, "lastIncompleteCheckout.id")
    ) {
      syncGuestCartWithCustomerCart({
        guestCheckout: guestCart,
        customer,
        checkoutLineItemsAdd,
        checkoutCreate,
      })
    }

    return () => setCheckingOutShopify(false)
  }, [guestCart?.id, customer])
  useEffect(() => {
    const {tracking} = state;
    if (cart && !cart.orderStatusUrl) {
      if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode) {
        const input = {
          customAttributes: [{key: 'referCode', value: tracking.referCode}, {
            key: 'customerId',
            value: tracking.customerId
          }]
        };
        const checkoutId = cart.id.toString();
        addAttributes({
          variables: {
            checkoutId,
            input
          }
        }).then(result => {
          const discountCode = tracking.discountCode;
          applyDiscount({
            variables: {
              checkoutId,
              discountCode
            }
          }).then(result => console.info(result))

        }).catch(e => console.error(e))

      } else {
        const checkoutId = cart.id;
        removeDiscount({variables: {checkoutId}})
      }


    } else if (rechargeCart && !rechargeCart.completed_at) {
      if (!rechargeCart.discount_code) {
        if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode) {
          const trackingParams = {referCode: tracking.referCode, customerId: tracking.customerId}

          const checkoutUpdateOptions = {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
              lineItems: rechargeCart.lineItems,
              checkoutToken: rechargeCart.token,
              tracking: trackingParams
            }),
            referrerPolicy: 'origin-when-cross-origin'
          }
          fetch(`${process.env.GATSBY_RECHARGE_API_ENDPOINT ? process.env.GATSBY_RECHARGE_API_ENDPOINT : ''}/api/checkout/update`, checkoutUpdateOptions)
            .then(response =>
              response.json()
            ).then(result => {
            const {checkout = {}} = result;

          })
        }
      }
    }
  }, [cart, state, rechargeCart])

  const getSubTotal = () => {
    const items = get(cart, "lineItems.edges", [])
    const subTotal = items
      .reduce(
        (acc, edge) =>
          parseFloat(edge.node.variant.price) * parseFloat(edge.node.quantity) +
          acc,
        0
      )
      .toFixed(2)
    return subTotal
  }
  const getDiscount = () => {
    const items = get(cart, "lineItems.edges", [])
    let subTotal = items
      .reduce(
        (acc, edge) =>
          parseFloat(edge.node.variant.price) * parseFloat(edge.node.quantity) +
          acc,
        0
      );
    subTotal = parseFloat(subTotal) / 0.1;
    return subTotal.toFixed(2)
  }

  const getPercent = () => {
    const subTotal = getSubTotal()
    const percentage = (subTotal / 30) * 100
    return parseFloat(percentage)
  }

  const handleRedirectToPDP = node => {
    const isProduct = node.variant.title === "Default Title"
    isProduct
      ? navigate(`/products/${node.variant.product.handle}`)
      : navigate(
      `/products/${
        node.variant.product.handle
      }/${node.variant.title.toLowerCase()}`
      )
    toggleCart({variables: {cartDrawerVisible: false}})
  }

  if (rechargeCart) {
    return (
      <SideDrawer
        isOpen={isOpen}
        onClose={onClose}
        style={{overscrollBehavior: "contain"}}
        isCart="true"
      >
        <RechargeCartModal
          cart={cart}
          rechargeCart={rechargeCart}
          onClose={onClose}
          email={customer?.email}
          toggleCart={toggleCart}
          checkoutCreate={checkoutCreate}
          replaceCheckoutLineItems={replaceCheckoutLineItems}
          updateRechargeCheckout={updateRechargeCheckoutInCache}
          removeCheckoutLineItems={removeCheckoutLineItems}
          setLoading={setLoading}
        />
      </SideDrawer>
    )
  }
  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      style={{overscrollBehavior: "contain"}}
      isCart="true"
    >
      <Div h="100%" d="flex" flexDir="column">
        <Div
          d="flex"
          justify="space-between"
          align="center"
          m={{b: "2.25rem"}}
          p={{r: "1rem"}}
        >
          <Text
            textTransform="uppercase"
            textWeight="bold"
            fontFamily="secondary"
            textSize="display1"
          >
            cart
          </Text>
          <Div
            style={{lineHeight: "0"}}
            bg="lightPink"
            shadow="2"
            hoverShadow="3"
            transition
            cursor="pointer"
            rounded="sm"
            onClick={() => onClose(false)}
          >
            <Image src="/close.svg" h="1rem" w="1rem" p="10px"/>
          </Div>
        </Div>
        {loading ? (<Div
          flexGrow="1"
          overflow="auto"
          flexWrap="nowrap"
          p={{b: {xs: "2.5rem", sm: "0rem"}, r: "1.5rem"}}
          m={{r: "-1.5rem"}}
          justify="center"
          align="center"
        >
          <Div flexGrow="1 1 0%" m={{t: "2rem"}} justify="center" align="center">
            <Icon
              name="Loading"
              transform="translateY(-50%)"
              size="25px"
              color="blue"
              style={{zIndex: 1, opacity: 1}}
              m={{r: "0.5rem"}}
            /></Div>
        </Div>) : (
          <Div
            className="cartitems-container"
            flexGrow="1"
            overflow="auto"
            flexWrap="nowrap"
            p={{r: "1.5rem"}}
            m={{b: {xs: "11.3rem", sm: "0rem"}, r: "-1.5rem"}}
            style={{overscrollBehavior: "contain"}}
          >
            {get(cart, "lineItems.edges", []).length > 0 && (
              <Div
                d="flex"
                flexDir="column"
                m={{r: "1rem"}}
                border={{t: "1px solid"}}
                borderColor="yellowPink"
                textWeight="500"
                p={{y: "2rem"}}
              >
                <Div p={{y: "0.3rem"}} d="flex" flexDir="column">
                  <Text textSize="body" m={{b: ".8rem"}}>
                    {getSubTotal() >= 30
                      ? "Congrats! You get free standard shipping."
                      : `You're $${(30 - getSubTotal()).toFixed(
                        2
                      )} away from free shipping.`}
                  </Text>
                  <div style={{position: "relative"}}>
                    <Slider
                      disabled={true}
                      value={getPercent()}
                      railStyle={{
                        backgroundColor: "#F8D4E4",
                        height: "4px",
                        borderRadius: "100px",
                      }}
                      trackStyle={{
                        backgroundColor: "#283377",
                        height: "4px",
                        borderRadius: "100px",
                        position: "absolute",
                        top: "0",
                      }}
                      handleStyle={{
                        display: getSubTotal() >= 30 ? "none" : "block",
                        position: "absolute",
                        backgroundColor: "#F8D4E4",
                        height: "16px",
                        width: "16px",
                        borderRadius: "100px",
                        top: "-6px",
                        transform: "translate(-50%)",
                        boxShadow:
                          " -8px -8px 12px #FFFFFF, 6px 6px 12px rgba(255, 211, 95, 0.3)",
                      }}
                    />
                  </div>
                </Div>
              </Div>
            )}

            {get(cart, "lineItems.edges", []).length === 0 && (
              <Div flexGrow="1 1 0%" m={{t: "2rem"}}>
                <Text>Your Bag is Empty</Text>
              </Div>
            )}
            {get(cart, "lineItems.edges", []).map(edge => {
              return (
                <React.Fragment key={edge.node.id}>
                  {/* {[1, 2, 3].map(id => {
            return ( */}
                  <Div
                    p={{y: "2.5rem"}}
                    m={{r: "1rem"}}
                    border={{t: "1px solid"}}
                    borderColor="yellowPink"
                    d="flex"
                    //key={id}
                  >
                    <Div
                      onClick={() => handleRedirectToPDP(edge.node)}
                      cursor="pointer"
                      bgImg={get(edge, "node.variant.image.originalSrc")}
                      bgPos="center"
                      bgSize="cover"
                      rounded="10px"
                      m={{r: "1.25rem"}}
                      h="4rem"
                      w="4rem"
                    />
                    <Div flexGrow="1">
                      <Div d="flex" justify="space-between" align="center">
                        <Text
                          onClick={() => handleRedirectToPDP(edge.node)}
                          cursor="pointer"
                          textWeight="bold"
                        >
                          {edge.node.title}
                        </Text>
                        <Div
                          style={{lineHeight: "0"}}
                          bg="lightPink"
                          shadow="2"
                          cursor="pointer"
                          rounded="circle"
                          m={{l: "20px"}}
                          onClick={() => onRemoveItem(edge.node)}
                        >
                          <Image src="/close.svg" h="10px" w="10px" p="5px"/>
                        </Div>
                      </Div>
                      <Div>
                        {edge.node.variant.title !== "Default Title" && (
                          <Text m={{t: "10px"}} textSize="paragraph1">
                            {edge.node.variant.title}
                          </Text>
                        )}
                        <Text m={{t: "5px"}} textSize="paragraph1">
                          $
                          {(edge.node.variant.price * edge.node.quantity).toFixed(
                            2
                          )}
                        </Text>
                        {/* //Counter for products */}
                        <Div
                          d="flex"
                          rounded="lg"
                          m={{t: "10px"}}
                          shadow="2"
                          maxW="4rem"
                          justify="space-between"
                        >
                          <Text
                            textWeight="bold"
                            p="2px 10px"
                            cursor="pointer"
                            onClick={() => onDecreaseQuantity(edge.node)}
                            // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                          >
                            -
                          </Text>
                          <Text textWeight="bold" p="2px 0">
                            {/* {replaceCheckoutLineItemLoading ? (
                            <Image src="/loader.svg" />
                          ) : ( */}
                            {edge.node.quantity}
                            {/* )} */}
                          </Text>
                          <Text
                            textWeight="bold"
                            p="2px 10px"
                            cursor="pointer"
                            onClick={() => onIncreaseQuantity(edge.node)}
                            // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                          >
                            +
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </React.Fragment>
              )
            })}
            {get(cart, "lineItems.edges", []).length > 0 && (
              <Div
                d="flex"
                flexDir="column"
                m={{r: "1rem"}}
                border={{t: "1px solid"}}
                borderColor="yellowPink"
                textWeight="500"
                p={{y: "2rem"}}
              >
                <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                  <Text textSize="body">Subtotal</Text>
                  <Text textSize="body" textWeight="700">
                    ${getSubTotal()}
                  </Text>
                </Div>
                <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                  <Text textSize="body">Tax</Text>
                  <Text textSize="body" style={{color: "#6C97C4"}}>
                    Calculated in checkout
                  </Text>
                </Div>
                <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                  <Text textSize="body">Shipping</Text>

                  <Text textSize="body" style={{color: "#6C97C4"}}>
                    Calculated in checkout
                  </Text>
                </Div>
                {cart?.discountApplications?.edges.length ?
                  <Div p={{y: "0.3rem"}} d="flex" justify="space-between">
                    <Text textSize="body">Discount</Text>
                    <Text textSize="body">
                      (${(parseFloat(cart?.lineItemsSubtotalPrice.amount) * .1).toFixed(3).slice(0, -1)})
                    </Text>
                  </Div> : ''
                }
              </Div>
            )}
          </Div>
        )}
        {get(cart, "lineItems.edges", []).length > 0 && (
          <Div
            m={{r: {sm: "1rem"}}}
            pos={{xs: "fixed", sm: "static"}}
            left="1rem"
            right="1rem"
            bottom="0rem"
            w={{sm: "100%"}}
          >
            <Div
              className="safe-margin"
              style={{
                marginRight: "-1.5rem",
                display: "flex",
                flexDirection: "column",
              }}
              m={{l: {sm: "-36px", xs: "-26px"}}}
              p={{b: "20px", x: {sm: "25px", xs: "50px"}}}
            >
              <Div
                d="flex"
                justify="space-between"
                border={{t: "1px solid"}}
                borderColor="yellowPink"
                p={{y: "20px"}}
                style={{alignSelf: "center"}}
                w={{lg: "295px", xs: "100%"}}
              >
                <Text textSize="body">Estimated Total</Text>
                <Text textSize="body" textWeight="700">
                  $
                  {cart?.discountApplications?.edges.length ?
                    (parseFloat(getSubTotal()) - (parseFloat(cart?.lineItemsSubtotalPrice.amount) * .1).toFixed(3).slice(0, -1)).toFixed(2) : getSubTotal()
                  }

                </Text>

              </Div>
              <BlueButton
                style={{alignSelf: "center"}}
                w={{lg: "295px", xs: "100%"}}
                text="Checkout"
                //padding="13px 74px"
                rounded="lg"
                onClick={() => {
                  trackCheckout(cart.lineItemsSubtotalPrice.amount)
                  setCheckingOutShopify(true)
                  window._learnq = window._learnq || []
                  window._learnq.push([
                    "track",
                    "Started Checkout",
                    {
                      $event_id: `${cart.id}_${Date.now()}`,
                      $value: cart.lineItemsSubtotalPrice.amount,
                      ItemNames: cart.lineItems.edges.map(
                        ({node}) => node.title
                      ),
                      CheckoutURL: cart?.webUrl,
                      Items: cart.lineItems.edges.map(edge => {
                        const {node} = edge

                        return {
                          ProductID: atob(node.variant.id)
                            .split("/")
                            .slice(-1)[0],
                          SKU: node.variant.sku,
                          ProductName: node.title,
                          Quantity: node.quantity,
                          ItemPrice: node.variant.price,
                          RowTotal: node.variant.price * node.quantity,
                          ProductURL: `https://notpot.com/products/${node.variant.product.handle}`,
                          ImageURL: node.variant.image.originalSrc,
                        }
                      }),
                    },
                  ])
                  {
                    /* removels() */
                  }
                  window.location.href = cart?.webUrl
                }}
                disabled={checkingOutShopify}
                prefix={
                  <Icon
                    name="Loading"
                    pos="absolute"
                    top="50%"
                    left="8.5%"
                    transform="translateY(-50%)"
                    size="25px"
                    color="white"
                    style={{zIndex: 1, opacity: checkingOutShopify ? 1 : 0}}
                    m={{r: "0.5rem"}}
                  />
                }
              />
              <Div
                m={{t: "20px"}}
                fontFamily="primary"
                d="flex"
                align="center"
                justify="center"
              >
                <Text
                  style={{textAlign: "center", whiteSpace: "pre"}}
                  className="cart-sezzle"
                  textSize="body"
                  textWeight="500"
                >
                  Or 4 interest-free payments of{" "}
                  <span style={{fontWeight: "bold"}}>
                    ${Math.round(getSubTotal() / 4)}
                  </span>{" "}
                  with
                  <img src="/Vector.svg" style={{marginLeft: 4}}/>
                </Text>
              </Div>
            </Div>
          </Div>
        )}
      </Div>
    </SideDrawer>
  )
}

export default CartModal
