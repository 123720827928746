import {graphql} from "gatsby"
import {gql} from "@apollo/client"

export const SITE_SETTINGS = graphql`
  fragment SiteSettings on SanitySiteSettings {
    title
    navMenu {
      ...NavMenu
    }
    footerNavMenu {
      ...NavMenu
    }
    socialLinks {
      title
      icon {
        asset {
          url
        }
      }
      link
    }
    openGraph {
      title
      description
    }
    headComponents
    notSpamForm {
      header
      body
      icon {
        asset {
          url
        }
      }
      sideImage {
        asset {
          url
        }
      }
    }
  }
`

export const NavMenu = graphql`
  fragment NavMenu on SanityNavigationMenu {
    title
    items {
      title
      kind
      link
      route
      type
      landingPageRoute {
        ... on SanityRoute {
          id
          _type
          slug {
            current
          }
        }
      }
    }
  }
`

export const PostInfo = graphql`
  fragment PageInfo on SanityPage {
    id
    _rawContent(resolveReferences: { maxDepth: 20 })
    title
    seo{
      seo_title
      meta_description
      focus_synonyms
      focus_keyword
    }
  }
`
export const SanityImage = graphql`
  fragment SanityImage on SanityMainImage {
    alt
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      metadata {
        lqip
        dimensions {
          aspectRatio
          width
          height
        }
      }
    }
  }
`

export const DISCOUNT_APPLICATION_FRAGMENT = gql`
  fragment DiscountApplicationFragment on DiscountApplication {
    targetSelection
    allocationMethod
    targetType
    value {
      ... on MoneyV2 {
        amount
        currencyCode
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    ... on ManualDiscountApplication {
      title
      description
    }
    ... on DiscountCodeApplication {
      code
      applicable
    }
    ... on ScriptDiscountApplication {
      description
    }
    ... on AutomaticDiscountApplication {
      title
    }
  }
`

export const CHECKOUT_FIELDS = gql`
  fragment checkoutFields on Checkout {
    id
    email
    webUrl
    orderStatusUrl
    shippingAddress {
      formatted
      firstName
      lastName
      address1
      province
      country
      zip
    }
    customAttributes {
      key
      value
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    discountApplications(first: 1) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...DiscountApplicationFragment
        }
      }
    }

    lineItems(first: 10) {
      edges {
        cursor
        node {
          id
          quantity
          title


          variant {
            product {
              handle
              id
              availableForSale
            }
            id
            sku
            title
            price
            image {
              originalSrc
            }
            selectedOptions {
              name
              value
            }
          }
          customAttributes {
            key
            value
          }

          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication {
              ...DiscountApplicationFragment
            }
          }

        }
      }
    }
    totalPriceV2 {
      amount
      currencyCode
    }

  }
  ${DISCOUNT_APPLICATION_FRAGMENT}
`
export const Checkout_User_Error_Fragment = gql`
  fragment CheckoutUserErrorFragment on CheckoutUserError {
    field
    message
    code
  }

`
export const CUSTOMER_FIELDS = gql`
  fragment customerFields on Customer {
    id
    email
    firstName
    lastName
    phone
    lastIncompleteCheckout {
      ...checkoutFields
    }
  }
`
