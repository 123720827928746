import React from "react"
import { Div, Text, Image, Input, Anchor, Button } from "atomize"

import SignupForm from "./SignupForm"
import SideDrawer from "../atoms/SideDrawer"

const SignupModal = ({ isOpen, onClose, onClickLoginBtn }) => {
  return (
    <SideDrawer isOpen={isOpen} onClose={onClose}>
      <Div
        d="flex"
        justify="space-between"
        align="center"
        m={{ b: "2.5rem" }}
        p={{ b: "2.5rem" }}
        border={{ b: "1px solid" }}
        borderColor="yellowPink"
      >
        <Text
          textTransform="uppercase"
          textWeight="bold"
          fontFamily="secondary"
          textSize="display1"
        >
          Signup
        </Text>
        <Div
          style={{ lineHeight: "0" }}
          bg="lightPink"
          shadow="2"
          hoverShadow="3"
          cursor="pointer"
          rounded="sm"
          onClick={onClose}
        >
          <Image src="/close.svg" h="1rem" w="1rem" p="10px" />
        </Div>
      </Div>

      <SignupForm />
      <Button
        shadow="2"
        m={{ t: "2.5rem" }}
        hoverShadow="4"
        bg="pink"
        textTransform="uppercase"
        rounded="circle"
        textColor="blue"
        textWeight="bold"
        fontFamily="secondary"
        textSize="header1"
        h="2.5rem"
        overflow="hidden"
        onClick={onClickLoginBtn}
      >
        Login
      </Button>
    </SideDrawer>
  )
}

export default SignupModal
