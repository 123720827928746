import React, { useState, useEffect } from "react"
import { Transition } from "react-transition-group"
import moment from "moment"
import _ from "lodash"
import * as yup from "yup"
import axios from "axios"
import { Formik } from "formik"
import {Div, Anchor, Row, Col, Input, Text, Icon, Image} from "atomize"
import CardHeader from "../CardHeader"
import BlueButton from "../molecules/BlueButton"
// import {
//   disableBodyScroll,
//   enableBodyScroll,
//   clearAllBodyScrollLocks,
// } from "body-scroll-lock"

export default function NotSpamModal({notSpamForm}) {
  const [emailPopup, setEmailPopup] = useState(false)

  useEffect(() => {
    if (
      !localStorage.getItem("emailPopupClosed") ||
      moment().diff(localStorage.getItem("emailPopupClosed"), "days") >= 1
    ) {
      setTimeout(() => {
        setEmailPopup(true)
      }, 1000)
    } else {
      setEmailPopup(false)
    }
    return () => {}
  }, [])

  const duration = 300

  const defaultStyle = {
    opacity: "0",
    transition: "opacity 0.2s ease-in-out",
  }

  const transitionStyles = {
    entering: { opacity: "0" },
    entered: { opacity: "1" },
    exiting: { opacity: "0" },
    exited: { opacity: "0" },
  }

  let formikProps

  const initialValues = {
    phone: "",
    email: "",
  }

  const onSubmit = values => {
    const { setSubmitting, setStatus, resetForm } = formikProps
    const klaviyoPromise = axios.post(`/api/klaviyo`, {
      email: values.email,
      phone: "+1" + values.phone,
    })

    const postscriptPromise = axios.post(`/api/postscript`, {
      phone: values.phone,
    })

    Promise.all([klaviyoPromise, postscriptPromise])
      .then(responses => {
        setSubmitting(false)
        setInterval(() => {
          setEmailPopup(false)
        }, 2000)
        resetForm(initialValues)
        setStatus({
          successMessage:
            "Thanks for signing up — check your phone for the code!",
          errorMessage: "",
        })
      })
      .catch(error => {
        setSubmitting(false)
        setStatus({
          errorMessage: "Something went wrong. Please try again later.",
          successMessage: "",
        })
      })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
    phone: yup.string().required("Please use a valid phone number.").min(10),
  })

  return (
    <Transition
      in={emailPopup}
      timeout={duration}
      // onExiting={() => {
      //   enableBodyScroll()
      // }}
      // onExited={clearAllBodyScrollLocks}
      unmountOnExit
    >
      {state => (
        <Div
          m={{ xs: "0 1.25rem", lg: "0" }}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          pos="fixed"
          bottom="4rem"
          left="0"
          right="0"
          zIndex="100"
        >
          <Row>
            <Col size={{ xs: "12", lg: "8" }} offset={{ xs: "0", lg: "2" }}>
              <Div
                bg="lightPink"
                border="1px solid"
                borderColor="yellowPink"
                rounded="lg"
                shadow="4"
                maxW="750px"
                m={{ x: "auto" }}
              >
                <CardHeader
                  heading={notSpamForm.header}
                  close="true"
                  image={notSpamForm.icon.asset.url}
                  closeModal={() => {
                    setEmailPopup(false)
                    localStorage.setItem("emailPopupClosed", moment())
                  }}
                />
                <Div
                  bg="yellow"
                  p="1.25rem"
                  rounded={{ b: "lg" }}
                  d="flex"
                  flexDir={{ xs: 'column', lg: 'row' }}
                >
                  {
                    notSpamForm.sideImage?.asset?.url &&
                    <Div m={{xs: '0 auto'}} d='flex' flexDir='row'>
                      <Image
                        src={notSpamForm.sideImage.asset.url}
                        h="80px"
                        w="80px"
                        m={{t: {lg: '0.5rem'}, r: {xs: '0.5rem', lg: '1rem'}}}
                      />
                      <Text d={{lg: 'none', xs: 'block'}}>{notSpamForm.body}</Text>
                    </Div>
                  }
                  <Div d='flex' flexDir='column'>
                    <Text d={{xs: 'none', lg: 'block'}}>{notSpamForm.body}</Text>
                    <Formik
                      initialValues={{
                        phone: "",
                        email: "",
                      }}
                      onSubmit={onSubmit}
                      validationSchema={validator}
                    >
                      {props => {
                        const {
                          values,
                          status,
                          touched,
                          errors,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        } = props
                        formikProps = props
                        return (
                          <form onSubmit={handleSubmit}>
                            {status && status.successMessage && (
                              <Text
                                textColor="success700"
                                m={{ y: "1rem" }}
                                opacity={status.successMessage ? "1" : "0"}
                                transition
                              >
                                {status.successMessage}
                              </Text>
                            )}
                            {status && status.errorMessage && (
                              <Text
                                textColor="danger700"
                                m={{ b: "1rem" }}
                                opacity={status.errorMessage ? "1" : "0"}
                                transition
                              >
                                {status.errorMessage}
                              </Text>
                            )}
                            <Div
                              d={{ xs: "block", md: "flex" }}
                              m={{ t: "1.25rem" }}
                            >
                              <Div
                                m={{ r: { xs: "0", md: "0.625rem" } }}
                                flexGrow="1"
                              >
                                <Input
                                  type="text"
                                  name="phone"
                                  placeholder="Phone Number"
                                  placeholderTextColor="navy"
                                  shadow="2"
                                  textColor="blue"
                                  h="2.5rem"
                                  border="1px solid"
                                  borderColor={
                                    touched.phone
                                      ? values.phone
                                      ? errors.phone
                                        ? "red"
                                        : "oilGreen"
                                      : errors.phone
                                        ? "red"
                                        : "transparent"
                                      : "transparent"
                                  }
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Text
                                  textColor="error"
                                  textSize="paragraph"
                                  m={{ t: ".5rem" }}
                                >
                                  {touched.phone && _.capitalize(errors.phone)}
                                </Text>
                              </Div>
                              <Div
                                m={{ r: { xs: "0", md: "0.625rem" } }}
                                flexGrow="1"
                              >
                                <Input
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  placeholderTextColor="navy"
                                  shadow="2"
                                  textColor="blue"
                                  h="2.5rem"
                                  border="1px solid"
                                  borderColor={
                                    touched.email
                                      ? values.email
                                      ? errors.email
                                        ? "red"
                                        : "oilGreen"
                                      : errors.email
                                        ? "red"
                                        : "transparent"
                                      : "transparent"
                                  }
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Text
                                  textColor="error"
                                  textSize="paragraph"
                                  m={{ t: ".5rem" }}
                                >
                                  {touched.email && _.capitalize(errors.email)}
                                </Text>
                              </Div>

                              <BlueButton
                                type="submit"
                                text="sign up"
                                padding="0.5rem 1.25rem"
                                textSize="header1"
                                h="2.5rem"
                                minW="6.25rem"
                                w={{ xs: "100%", md: "6.25rem" }}
                                text={
                                  isSubmitting ? (
                                    <Icon
                                      name="Loading"
                                      color="white"
                                      size="16px"
                                    />
                                  ) : (
                                    "Sign Up"
                                  )
                                }
                              />
                            </Div>
                            <Text m={{ t: "0.625rem" }} textSize="caption">
                              By signing up, you agree to receive recurring
                              automated text messages at the phone number
                              provided. Consent is not a condition to purchase.
                              Msg & data rates may apply. View{" "}
                              <Anchor
                                textColor="blue"
                                hoverTextColor="blue"
                                textDecor="underline"
                              >
                                Terms{" "}
                              </Anchor>
                              &
                              <Anchor
                                textColor="blue"
                                hoverTextColor="blue"
                                textDecor="underline"
                              >
                                Privacy{" "}
                              </Anchor>
                              .
                            </Text>
                          </form>
                        )
                      }}
                    </Formik>
                  </Div>
                </Div>
              </Div>
            </Col>
          </Row>
        </Div>
      )}
    </Transition>
  )
}
