import React, { useState, useRef, useEffect } from "react"
import { Div, Text, Anchor } from "atomize"
import WebsiteContainer from "../atoms/WebsiteContainer"
import { Transition } from "react-transition-group"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import Wave from "react-wavify"

export default function MobileHeaderMenu({
  navMenuItems,
  showMobileMenu,
  close,
  showLogin,
}) {
  const [height, setHeight] = useState(0)

  const updateHeight = () => {
    const mobileMenu = document.querySelector("#mobileHeaderMenu")

    disableBodyScroll(mobileMenu)
    const totalHeight = mobileMenu.getBoundingClientRect().height

    setHeight(totalHeight)
  }

  const duration = 300

  /**
   * Default Style for Backlayer
   */
  const defaultbackLayerStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  /**
   * Trasition Styles for Backlayer
   */
  const backLayerTransitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 0.5 },
    exiting: { opacity: 0 },
    exited: { opacity: 0, display: "none" },
  }

  const defaultStyle = {
    transition: `height ${duration}ms ease-in-out`,
    height: "0",
  }

  const transitionStyles = {
    entering: { height: 0 },
    entered: { height: height },
    exiting: { height: 0 },
    exited: { height: 0 },
  }

  return (
    <>
      <Transition
        in={showMobileMenu}
        timeout={duration}
        onEntering={updateHeight}
        onExiting={() => {
          enableBodyScroll();
        }}
        onExited={clearAllBodyScrollLocks}
      >
        {state => (
          <>
            <Div
              style={{
                ...defaultbackLayerStyle,
                ...backLayerTransitionStyles[state],
              }}
              pos="fixed"
              top="7rem"
              left="0"
              right="0"
              bottom="0"
              bg="white"
              zIndex="99"
              cursor="pointer"
              onClick={close}
            />
            <Div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              pos="fixed"
              top="6.25rem"
              left="0"
              right="0"
              zIndex="100"
              overflow="hidden"
              d={{ xs: "block", lg: "none" }}
            >
              <Div id="mobileHeaderMenu">
                <Div bg="pink">
                  <WebsiteContainer>
                    <Div p="1.25rem 0">
                      {navMenuItems
                        .filter(link => link.type !== "secondary")
                        .map((link, index, id) => {
                          return (
                            <Div
                              m={{
                                t: index === 0 ? "0" : "1.875rem",
                              }}
                              key={`mobileHeader${link.title}${index}`}
                            >
                              <Anchor
                                href={link.route}
                                key={id}
                                textColor="blue"
                                textWeight="bold"
                                textSize="header"
                                style={{ lineHeight: "16px" }}
                              >
                                {link.title}
                              </Anchor>
                            </Div>
                          )
                        })}
                      <Div
                        m={{ t: "1.875rem" }}
                        border={{ t: "1px solid" }}
                        borderColor="borderColor"
                      >
                        {navMenuItems
                          .filter(link => link.type === "secondary")
                          .map((link, id) => {
                            return (
                              <Div
                                m="1.875rem 0 0"
                                key={`mobileHeader${link.title}${id}`}
                              >
                                <Anchor
                                  href={link.route}
                                  key={id}
                                  textColor="blue"
                                  textWeight="bold"
                                  textSize="header"
                                  style={{ lineHeight: "16px" }}
                                >
                                  {link.title}
                                </Anchor>
                              </Div>
                            )
                          })}
                        <Div m="1.875rem 0 0">
                          <Text
                            onClick={showLogin}
                            textColor="blue"
                            textWeight="bold"
                            textSize="header"
                            style={{ lineHeight: "16px" }}
                          >
                            Sign In
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </WebsiteContainer>
                </Div>
                <Div
                  transform="translateY(-10px)rotate(180deg)"
                  onClick={close}
                >
                  <Wave
                    fill="#fdeeff"
                    filter="url(#waveFilterMobileHeader)"
                    paused={!showMobileMenu}
                    options={{
                      height: 84,
                      points: 3,
                      amplitude: 40,
                      speed: 0.25,
                    }}
                  >
                    <defs>
                      <filter
                        id="waveFilterMobileHeader"
                        x="-100%"
                        y="-100%"
                        width="200%"
                        height="200%"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feOffset
                          result="offOut"
                          in="SourceGraphic"
                          dx="0"
                          dy="-10"
                        />
                        <feColorMatrix
                          result="matrixOut"
                          in="offOut"
                          type="matrix"
                          values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
                        />
                        <feGaussianBlur
                          result="blurOut"
                          in="matrixOut"
                          stdDeviation="16"
                        />
                        <feBlend
                          in="SourceGraphic"
                          in2="blurOut"
                          mode="normal"
                        />
                      </filter>
                    </defs>
                  </Wave>
                </Div>
              </Div>
            </Div>
          </>
        )}
      </Transition>
    </>
  )
}
