import React from "react"
import { Image, Button, Text, Div } from "atomize"
import { navigate } from "@reach/router"

export default function BlueButton({
                                     text = "No text",
                                     h = "3.5rem",
                                     textSize = "button",
                                     route,
                                     onClick,
                                     className,
                                     ...rest
                                   }) {
  return (
    <Button
      shadow="2"
      hoverShadow="4"
      bg="green"
      textTransform="uppercase"
      rounded="default"
      textColor="blue"
      textWeight="bold"
      fontFamily="secondary"
      textSize={textSize}
      h={h}
      className={`blue-button ${className}`}
      overflow="hidden"
      onClick={route ? () => navigate(route) : onClick}
      {...rest}
    >
      <Div
        pos="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        bg="radial-gradient(75.84% 67.99% at 75.28% 50%, rgba(255, 255, 255, 0) 0%, #F8D4E4 100%);"
        opacity="0.8"
        rounded="default"
        style={{ filter: "blur(5px)" }}
      />
      <Div
        pos="absolute"
        top="0"
        left="0"
        h="100%"
        w="100%"
        bg="radial-gradient(75.84% 67.99% at 75.28% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);"
        opacity="0.5"
        rounded="default"
        style={{ filter: "blur(5px)" }}
      />
      <svg
        className="button-ellipse"
        width="162"
        height="30"
        viewBox="0 0 162 30"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M116.838 20.1574C136.358 24.9554 153.175 32.4105 153.175 27.9394C153.175 24.2534 170.691 8.23074 155.634 3.00023C142.559 -1.5418 100.905 3.00008 83.2325 3.00008C65.5595 3.00008 19.8074 -1.54189 6.73242 3.00014C-8.3245 8.23065 7.8253 24.2534 7.8253 27.9394C7.8253 32.3443 24.149 25.1733 43.2985 20.3719C43.8702 20.2286 44.4759 20.1719 45.0639 20.2118C59.8085 21.2121 55.0499 26.5002 80.5003 26.5002C106.456 26.5002 106.291 17.5649 116.838 20.1574Z"
          fill="#B3D9D5"
        />
      </svg>
      <svg
        className="button-shine"
        width="73"
        height="8"
        viewBox="0 0 73 8"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M70.9242 5.14078C70.9242 6.1738 55.5116 5.14078 36.4992 5.14078C17.4868 5.14078 2.07422 6.1738 2.07422 5.14078C2.07422 4.10777 17.4868 1.3999 36.4992 1.3999C55.5116 1.3999 70.9242 4.10777 70.9242 5.14078Z"
          fill="white"
        />
      </svg>

      <Text tag="span" pos="relative" zIndex="1">
        {text}
      </Text>
    </Button>
  )
}

// bg = '#B3D9D5 url("https://cdn.shopify.com/s/files/1/2229/0673/files/button-bg.svg?v=1595690702") no-repeat center center'
