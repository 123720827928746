import React from "react"
import { Div, Anchor, Text } from "atomize"
import { Link, navigate } from "gatsby"

import BlueButton from "./molecules/BlueButton"

const doNavigate = target => {
  if (!target || !target.length) {
    return
  }
  const internal = /^\/(?!\/)/.test(target)
  if (internal) {
    navigate(target)
  } else {
    window.location = target
  }
}

const CTALink = props => {
  const { route, link, landingPageRoute, kind, title, ...rest } = props

  let currentLink = route || link || "#"
  if (
    landingPageRoute &&
    landingPageRoute.slug &&
    landingPageRoute.slug.current
  ) {
    currentLink = landingPageRoute.slug.current
  }

  if (kind === "button:blue") {
    return <BlueButton text={title} {...rest} route={route} />
  }

  // External
  if (link) {
    return (
      <Anchor
        href={link}
        textSize="body"
        textColor="blue"
        d="block"
        style={{ lineHeight: "14px" }}
        hoverTextColor="rgb(108, 151, 196) !important"
        {...rest}
      >
        {title}
      </Anchor>
    )
  }

  return (
    <Link to={currentLink}>
      <Text
        textSize="body"
        hoverTextColor="rgb(108, 151, 196) !important"
        textColor="blue"
        style={{ lineHeight: "14px" }}
        {...rest}
      >
        {title}
      </Text>
    </Link>
  )
}

export default CTALink
