import React, { useEffect } from "react"
import { get } from "lodash"
import { ThemeProvider, StyleReset, Div } from "atomize"
import { useLocation } from "react-use"
import theme from "../Theme"
import Header from "./organisms/Header"
import Footer from "./organisms/Footer"
import NotSpamModal from "./organisms/NotSpamModal"

import "../../assets/scss/app.scss"

export default function Layout({ children, site }) {
  const location = useLocation()
  const navMenuItems = get(site, "navMenu.items", [])
  const footerMenuItems = get(site, "footerNavMenu", [])
  const socialLinks = get(site, "socialLinks", [])
  const notSpamForm = get(site, "notSpamForm", [])

  const scrollBgChange = () => {
    var body = document.body,
      bgBlocks = document.querySelectorAll(".bgblocks")
    // var scroll = window.scrollY + window.innerHeight / 3
    var offset = window.innerHeight / 3

    bgBlocks.forEach(section => {
      const dimentions = section.getBoundingClientRect()

      if (
        dimentions.top <= offset &&
        dimentions.top + dimentions.height > offset
      ) {
        body.style.backgroundColor = section.dataset.bg

        return null
      }
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollBgChange)

    return () => {
      window.removeEventListener("scroll", scrollBgChange)
    }
  }, [children])

  return (
    <ThemeProvider theme={theme}>
      <StyleReset />
      <Div
        // bg="bg2"
        // style={{ backgroundAttachment: "fixed" }}
        textColor="blue"
        overflow="hidden"
      >
        <Header navMenuItems={navMenuItems} />
        {children}

        {location.pathname === "/" && <NotSpamModal notSpamForm={notSpamForm}/>}
        <Footer footerMenuItems={footerMenuItems} socialLinks={socialLinks} notSpamForm={notSpamForm} />
      </Div>
    </ThemeProvider>
  )
}

Layout.defaultProps = {
  navMenuItems: [],
  footerMenuItems: [],
  socialLinks: [],
}
