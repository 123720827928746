import {get} from "lodash"

/**
 * hex to alpha numeric
 * @param {*} hex
 */

export const decodeHTML = html => {
  try {
    const txt = document.createElement("textarea")
    txt.innerHTML = html
    return txt.value
  } catch (e) {
  }

  return html
}

/**
 * transformer mutation errors
 *
 * @param {*} key
 * @param {*} errors
 */
export const transformMutationErrors = errors => {
  const transformed = {}

  const errorMessage = get(errors, "0.message")

  transformed.message = errorMessage
    ? errorMessage
    : "Request contain some non-validated data."

  return transformed
}

/**
 * transforms errors to format formik needs
 *
 * @param  {[type]}
 * @return {[type]}
 */
export const transformErrorsForFormik = error => {
  const transformed = {}

  if (error.errors) {
    Object.keys(error.errors).map(key => {
      transformed[key] = error.errors[key]
    })
  }

  return transformed
}

export const formatPhoneNumber = phone => {
  var cleaned = ("" + phone).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ["+1", match[2], match[3], match[4]].join("")
  }
  return phone
}

export const addDaysToDate = (theDate, days) => {
  return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000)
}

export const getProductFeatureImage = product => {
  const featureImage = get(product, "featuredImage.asset.url")
  return featureImage
    ? featureImage
    : get(product, "defaultVariant.carousel.assets.0.image.asset.url")
}

export const getVariantFeatureImage = variant => {
  const featureImage = get(variant, "featuredImage.asset.url")
  return featureImage
    ? featureImage
    : get(variant, "carousel.assets.0.image.asset.url")
}

export const getProductBubbleImage = product => {
  const bubbleImage = get(product, "bubbleImage.asset.url")

  return bubbleImage ? bubbleImage : "/bubble-product-image.png"
}

export const getProductHoverImage = product => {
  const assets = product.variants[0]?.carousel.assets
  const reqAsset = assets?.find((asset, i) => i > 0 && !asset.video)
  return reqAsset ? reqAsset.image.asset.url : undefined
}

export const getVariantHoverImage = variant => {
  const assets = variant?.carousel?.assets
  const reqAsset = assets?.find((asset, i) => i > 0 && !asset.video)
  return reqAsset ? reqAsset.image.asset.url : undefined
}

/**
 * encodes the object into encoded uri caomponent
 *
 * @param {*} x
 */
export const formUrlEncoded = x =>
  Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, "")
